.cus-cooperation.form
  .custom-row 
    align-items: flex-start
  .about-us 
    position: relative
    &::before 
      content: ''
      position: absolute
      top: -70px
      left: 0
      width: 100%
      height: calc(100% + 70px)
      background: #efefef
      z-index: -1
    .cus-col-12  
      max-width: 60%
      margin: auto
    .txt 
      box-shadow: none
      background: #efefef
      padding: 80px 50px
      h2.title 
        text-align: center
      p 
        color: #474747
        font-size: 16px  
        line-height: 2
        
  .cus-contact
    margin-bottom: 60px   
    h2.title 
      text-align: center
      margin-bottom: 40px
  .company-info 
    .title-area
      border-bottom: 2px solid #d4d4d4
      h2 
        margin-bottom: 30px
        color: #15327a
        font-weight: bold
        font-size: 18px
        letter-spacing: 1px
          
      span 
        display: block
        margin-top: 5px
        color: #474747
        font-size: 16px
        font-style: italic
        letter-spacing: 1px
    .info-area
      margin-top: 30px
      > div 
        margin-bottom: 20px
      p 
        display: inline    
      p, a 
        color: #474747
        letter-spacing: 1px
        font-weight: bold
        font-size: 16px
       
        
  #company-form  
    padding-bottom: 40px
    form 
      display: flex
      flex-wrap: wrap
      > p, > div 
        width: 100%
      p 
        font-size: 16px  
      p:nth-child(2), p:nth-child(3)
        box-sizing: border-box
        width: calc(50% - 15px)
      p:nth-child(2)
        margin-right: 15px  
      p:nth-child(3)
        margin-left: 15px  
      input[type = "submit"]
        display: block
        margin: auto
        background: #fff
        color: #828282
        border-color: #828282
        margin-top: 10px
        padding: 3px 10px
        border-radius: 5px

    label 
      width: 100%  
      color: #474747
      letter-spacing: 1px   
      font-weight: bold
      input, textarea 
        width: 100%
        border: none
        background: #efefef
@media screen and (max-width: 767px)
  .cus-cooperation.form
    .about-us 
      .cus-col-12  
        max-width: 100%
      .txt  
        padding: 20px 15px
        margin-top: 0
        p 
          line-height: 1.5
          letter-spacing: 1px
    .company-info 
      .title-area
        text-align: center
        h2 
          font-size: 14px
          span 
            font-size: 12px      
      .info-area 
        text-align: center
        margin-bottom: 30px
@media screen and (max-width: 576px)      
  .cus-cooperation.form
    .about-us 
      .txt
        p 
          font-size: 14px
  