.custom-wrap
  width: 100%
  img 
    width: 100%

  > div
    margin: 100px 0

@media screen and (max-width: 767px)
  .custom-wrap > div
    margin: 15px 0

.custom-container
  width: 100%
  max-width: 1400px
  margin: auto
  padding-left: 15px
  padding-right: 15px
  z-index: 10
  position: relative

.custom-row
  box-sizing: border-box
  display: flex
  width: 100%
  flex-wrap: wrap

  &.closest
    padding-left: 15px
    padding-right: 15px

  &.col
    flex-direction: column

.custom-container
  [class^='cus-col-']
    box-sizing: border-box
    padding-left: 15px
    padding-right: 15px

  .custom-row.closest [class^='cus-col-']
    padding-left: 0
    padding-right: 0

.cus-col-3
  width: 25%

.cus-col-4
  width: 33.333333%

.cus-col-5
  width: 41.666667%

.cus-col-6
  width: 50%

.cus-col-7
  width: 58.333333%

.cus-col-8
  width: 66.666667%

.cus-col-9
  width: 75%

.cus-col-12
  width: 100%

.cus-col-caculate-5
  width: 20%

@media screen and (max-width: 576px)
  .cus-col-sm-6
    width: 50%
  .cus-col-sm-12  
    width: 100%

@media screen and (max-width: 767px)
  .cus-col-md-6
    width: 50%

  .cus-col-md-12
    width: 100%

@media screen and (max-width: 992px)
  .cus-col-lg-5
    width: 41.666667%

  .cus-col-lg-7
    width: 58.333333%

.custom-container.reverse .custom-row:nth-of-type(even)
  flex-direction: row-reverse
.custom-container.odd-reverse .custom-row:nth-of-type(odd)
  flex-direction: row-reverse  

@media screen and (max-width: 767px)
  .cus-order
    order: -1
