// 引入
// @import "./_include/_mixin.sass"
// @import "./_include/_color.sass"
// @import "./_include/_media.sass"

.kc-container
  max-width: 100%
  .kc_column, .kc_column_inner
    padding: 0


.sf-arrows .sf-with-ul
  padding-right: 15px!important
  &:after
    display: none

#header
  min-height: 0

.main-nav-right #main-nav  
  // margin: 0
  
#mobile-nav ul .menu-item-has-children i  
  color: #333
  width: 100%
  height: 43px
  &:before
    position: absolute
    right: 10px
    bottom: 50%
    transform: translate(0,50%)

html, body    
  background: none

#main  
  margin-top: 0

.wp-pagenavi 
  text-align: center
  padding: 0 0 30px 0
  span,a
    display: inline-block
    font-size: 14px
    // color: $color_main
    padding: 5px 15px
    margin: 0 2px
    // +t(.3s)
  span,a:hover
    background-color: #2e4757
    color: #fff

#main-nav-container,#main-nav
  // +clearfix

#mobile-nav-toggle  
  position: absolute
  margin: 0
  padding: 0
  top: 20px
  right: calc(5% + 10px)
  color: #333
  width: 20px
  span
    display: block
    margin: 7px 0
    background-color: #ddd
    // +t(.5s)
    // +size(35px,2px)

body.mobile-nav-active
  #mobile-nav-toggle 
    transform: rotate(180deg)
    span
      &:nth-of-type(2)
        opacity: 0
      &:nth-of-type(1)
        transform: rotate(45deg) translate(4px,9px)
      &:nth-of-type(3)
        transform: rotate(-45deg) translate(4px,-9px)  