.cus-cooperation 
  .container 
    width: 100%
    max-width: 1200px
  img 
    width: 100%
    max-height: 400px
    object-fit: cover
    object-position: center
  h2.title 
    text-align: left
    margin-bottom: 20px
  .custom-row 
    align-items: center     
  .about-us
    margin-bottom: 80px
    .custom-container 
      max-width: 1200px
    .custom-row
      .cus-col-5
        margin-right: -15%
        width: 50%
  .cus-col-5
    position: relative
    width: 50%
    z-index: 10
  .cus-col-7
    width: 55%
    margin-left: 10%
  .txt
    box-shadow: 0 0 5px rgba(0,0,0,.5)
    padding: 40px
    position: relative
    background: #fff
    text-align: center
    
    p
      text-align: left
      line-height: 1.8
      font-size: 16px
      letter-spacing: 2px

    ul 
      padding-left: 0
      text-align: left
      li 
        padding: 5px 0
  .blue-bg
    width: 120px
    margin: 0 auto 80px auto       
    
  .cus-fluid
    position: relative
    display: block
    padding: 80px 0
    width: 100%
    background-image: url("/wp-content/uploads/index_about_bg.png")
    background-repeat: no-repeat
    background-size: cover
    background-position: center right
   
  .about-history
    .custom-container
      max-width: 1200px
    .custom-row
      .cus-col-7
        margin-left: 0
        margin-right: 10%
      .cus-col-5
        margin-right: 0
        margin-left: -15%
  .cus-wrap      
    padding-bottom: 1px
        
@media screen and (max-width: 767px)
  .cus-cooperation 
    margin-top: 30px
    .cus-fluid 
      padding: 20px 0
      padding-bottom: 0
    h2.title  
      font-size: 18px
    .title
      font-size: 28px
      margin-top: 0
      margin-bottom: 30px
    .txt
      margin-top: 0px
      margin-bottom: 10px
      padding: 20px 30px
      p
        font-size: 14px

    .about-us, .about-history
      margin-top: 0
      margin-bottom: 0
      .custom-row
        margin-bottom: 30px
        .cus-col-5, .cus-col-7
          margin: 0
          width: 100%
          // margin: 0
      