.cus-org
  ul 
    margin-bottom: 0
  .blue-bg 
    width: 120px
    margin: 30px auto 80px auto  
  .org-history
    .custom-container 
      position: relative
      max-width: 900px
      &::after 
        content: ''
        position: absolute
        top: 0
        left: 50%
        transform: translateX(-50%)
        height: 100%
        width: 2px
        background: #737ca1  
      
    .custom-row   
      position: relative
      align-items: center
      &::after 
        content: ''
        position: absolute
        top: calc(50% - 5px)
        left: calc(50% - 5px)
        transform: translate(-50%, -50%)
        height: 10px
        width: 10px
        border: 1px solid #737ca1
        background: #fff
        transform: rotate(45deg)
        transition: .3s linear
        z-index: 100
      &.active::after 
        background: #737ca1
  .cus-col-6 
    position: relative
    &:nth-child(even) 
      &::before
        content: ''
        position: absolute
        left: -45px
        top: 50%
        transform: translateY(-50%)
        width: 30px
        height: 1px
        background: #7f86a3
    &:nth-child(odd)    
      &::after 
        content: ''
        position: absolute
        right: -45px
        top: 50%
        transform: translateY(-50%)
        width: 30px
        height: 1px
        background: #7f86a3
 
  h2.title 
    margin: 30px 0
    text-align: center 

  .org-wrap
    padding: 90px 0  

  .org-history 
    padding: 90px 0
    background-image: url('/wp-content/uploads/news_bg.png')
    background-size: cover
    background-repeat: no-repeat
    img 
      display: block
      max-width: 150px
      margin: auto  
    .custom-row + .custom-row
      margin-top: 30px
    .custom-row 
      .cus-col-6 
        position: relative
        display: flex
        justify-content: center
        align-items: center
      .year 
        margin-left: 30px
        color: #231815
      .txt 
        border: 1.5px solid #63709f
        border-radius: 10px
        padding: 20px  
        color: #231815
        
    .custom-row:nth-child(2n) 
      .cus-col-6 
        flex-direction: row-reverse   
      .year 
        margin-left: 0
        margin-right: 30px  
       
    
@media screen and (max-width: 767px)
  .cus-org
    .org-wrap
      padding: 30px 0  
    .cus-col-6 
      &:nth-child(even)
        &::before
          display: none
      &:nth-child(odd)   
        &::after
          display: none  
    .org-history 
      padding: 45px 0     
      .custom-row 
        .year
          margin-left: 10px 
      .custom-row:nth-child(2n)    
        .year
          margin-right: 10px  