.cus-news-page 
  background-image: url('/wp-content/uploads/news_bg-1.png')
  background-size: cover
  background-position: center center
  background-repeat: no-repeat
  #main-container.container-fluid
    margin-top: 60px
    width: 100%
    max-width: 1200px
    padding-left: 30px
    padding-right: 30px
    .col-md-9
      float: left
      width: 66.6666667%
    .col-md-3 
      width: 33.333333%  
      float: left
      .widget
        width: 80%
        margin-left: auto
  .entry-content
    &::after 
      content: ''
      display: block
      clear: both
    p 
      padding: 15px 0
      color: #797977
  article.post 
    padding: 0px 0 15px 0
    margin: 0 30px 30px
    background: transparent
    border-bottom: 1px solid #cfcfcb
  h2.entry-title 
    margin-top: 0
    a 
      font-size: 22px  
      color: #253756
    span 
      font-size: 12px  
      display: block
      color: #b6b7b1
      font-weight: normal
      margin-top: 5px
  .news-content 
    margin-top: 10px    
  .readmore 
    display: none  
  .blog-posts-navigation 
    border-top: none

  .widget 
    .widget-title, ul
      padding: 10px 0px 10px 18px
    .widget-title
      border-bottom: 2px solid #cacbc9
      margin-bottom: 0
      color: #253756
    ul 
      margin-left: 11px
      .post-date
        color: #b6b7b1
        font-size: 12px
        
    li 
      position: relative
      &::before 
        content: '>'
        position: absolute
        top: 0
        left: -10px
      a 
        margin-left: 5px  
@media screen and (max-width: 767px)
  .cus-news-page 
    #main-container.container-fluid
      margin-top: 30px
      .col-md-9
        float: none
      .col-md-9 
        width: 100%    
      .col-md-3 
        width: 70%
        .widget
          width: 100%
          margin-left: 0
          margin-right: auto
    .entry-title 
      a 
        font-size: 18px          
    .blog-posts-navigation
      margin-bottom: 0px    
