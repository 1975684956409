$colorBlue: #15327a
%bg 
  background-size: cover
  background-repeat: no-repeat
  background-position: center center
// @font-face
//   src: url('/asset/font/Arial.ttf')
//   font-family: Arial

* 
  font-family: Arial,'Microsoft JhengHei'!important
  margin: 0
  padding: 0
  list-style-type: none
body 
  opacity: 0
#page-builder-container 
  overflow: hidden
@media screen and (min-width: 999px)
  .kc-row-container.kc-container .kc-wrap-columns  
    width: 100%
  .kc-row-container.kc-container .kc-wrap-columns, .kc_row_inner
    margin-left: 0px
    margin-right: 0px
  .kc-container 
    padding-left: 0
    padding-right: 0
a[lang="zh-TW"] 
  display: none
#mobile-nav  
  a[lang="zh-TW"] 
    display: none
.btn.focus, .btn:focus, .btn:hover
  color: unset
h2.title 
  margin: 0
  font-style: italic
  font-weight: bold
  font-size: 22px  
  color: $colorBlue
  letter-spacing: 2px
  line-height: 1.3
  span 
    font-style: normal
    color: $colorBlue
img 
  width: 100%
  vertical-align: middle
a 
  text-decoration: none  
.clearfix::after
  content: ''
  display: block
  clear: both  
.container
  box-sizing: border-box
  width: 100%
  max-width: 1400px  
  margin: auto
  padding: 0 15px 0 15px
@media screen and (max-width: 767px)
  .container 
    padding: 0 30px 0 30px
.blue-bg
  display: block
  font-size: 16px
  border-radius: 5px  
  background: #15327a
  text-align: center
  padding: 5px 10px
  color: #fff
  text-decoration: none
.arrow
  width: 0
  height: 0
  border-top: 6px solid $colorBlue
  border-right: 10px solid transparent
  border-left: 10px solid transparent
// Header 
#header 
  background: #293957
  padding: 0 120px
  .sf-arrows .sf-with-ul 
    padding-right: unset
  .site-logo 
    margin: 12px 0 18px 0
    width: 100px


  #main-nav-container::after 
    content: ''
    display: block    
    clear: both

  span.co 
    > a
      color: #f1f3f4
      position: absolute
      top: 34px
      margin-left: 10px
      font-size: 16px
  #main-nav 
    margin-top: 24px
    li + li 
      position: relative
      margin-left: 1px
    > li   
      > a
        position: relative
        &::after 
            content: ''
            width: 1px
            height: 20px
            background: #fff
            display: block
            position: absolute
            top: 11px
            right: -1px
      &:last-child
        > a 
          &::after 
            display: none

          
    a 
      color: #f1f3f4
      padding: 10px 20px
      font-size: 15px
      &.sf-with-ul
        padding: 10px 20px !important
    a.en 
      border: 1px solid #fff
      padding: 3px 8px
      margin-top: 10px
      margin-left: 20px
      font-size: 12px
      letter-spacing: 1px
      line-height: normal
    > li > ul.sub-menu    
      margin-top: 23px
      left: 50%
      transform: translateX(-50%)
      text-align: center
      &::before 
        content: ''
        position: absolute
        top: 0
        left: 50%
        transform: translateX(-50%)
        width: 0
        height: 0
        border-style: solid
        border-width: 5px 7px 0 7px
        border-color: rgba(#4e9bd7, .9) transparent transparent transparent
        z-index: 100

      li, ul.sub-menu li 
        background: rgba(#f0f8fc, .85)
        &:hover 
          background: rgba(#4e9bd7, .9)  
          a 
            color: #fff
        &::before 
          display: none    
        a 
          color: #4c4847
@media screen and (max-width: 767px)
  #header  
    padding: 0 30px
    span.co 
      > a
        display: none
    .site-logo 
      width: 60px    
  #mobile-nav-toggle      
    color: #f1f3f4
  
// Slider  
.main-slider
  position: relative
  padding: 0 0 80px 0
    
  .img-group
    position: relative
    padding-bottom: 52%
    img 
      position: absolute
      top: 0
      left: 0
  .btn 
    border: 1px solid $colorBlue
    border-radius: 50%
    position: absolute
    top: 50%
    transform: translateY(-50%)
    cursor: pointer
    width: 30px
    height: 30px
    text-align: center
    transition: .3s
    &:hover 
      background: $colorBlue

    .arrow
      position: absolute
      top: 50%
      transform: translateY(-50%)
      border-top: 4px solid transparent
      border-bottom: 4px solid transparent
      transition: .3s
      
    &.left
      left: 50px
      .arrow
        border-right: 8px solid $colorBlue
        
    &.left:hover 
      .arrow
        border-right: 8px solid #fff   
      
    &.right
      right: 50px  
      .arrow
        left: 11px
        border-left: 8px solid $colorBlue
    &.right:hover 
      .arrow
        border-left: 8px solid #fff   
  @media screen and (max-width: 568px)  
    .btn.right
      right: 20px       
    .btn.left
      left: 20px       
  .container
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%   
  .mv 
    position: absolute
    top: 50%
    transform: translateY(-50%)
    left: 15%
    width: 25%
    height: 0
    padding-bottom: 25%
    margin-left: 120px
    .line-top
      position: absolute
      top: -10px
      left: -38%
      width: 60%  
      z-index: 10

    .line-bottom
      position: absolute
      bottom: -14%
      right: -21%
      width: 60%
      z-index: 10
  .intro 
    box-sizing: border-box
    position: absolute
    border: 10px solid #fff 
    color: #000
    font-size: 20px
    text-align: center
    width: 100%
    height: 100%
    background-image: url('/wp-content/uploads/banner_square.png')   
    @extend %bg
  p 
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%,-50%) 
    line-height: 2
@media screen and (max-width: 1010px)
  .main-slider
    p 
      line-height: 1.5
      font-size: 18px
@media screen and (max-width: 820px)
  .main-slider
    p 
      line-height: 1.5
      font-size: 16px
@media screen and (max-width: 576px)
  .main-slider 
    .mv 
      width: 40%
      padding-bottom: 40%
      left: 38%
      transform: translate(-50%,-50%)
      margin-left: 0
    p 
      line-height: 1.5 
      font-size: 14px   
      width: 78%
@media screen and (max-width: 400px)
  .main-slider
    p 
      letter-spacing: 1px
      line-height: 1.5
      font-size: 12px
@media screen and (max-width: 330px)
  .main-slider
    p 
      letter-spacing: 1px
      line-height: 1.2
      font-size: 12px
.cus-slider
  position: relative
  .title-wrap
    padding: 30px 50px
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    background: linear-gradient(45deg,rgba(#aaced8, .6), rgba(#e8c5cd, .6))
    border: 10px solid rgba(#fff, .6)
    z-index: 10

    h1.title 
      margin: 0
      font-style: italic
      font-weight: bold
      font-size: 22px
      color: #15327a
      letter-spacing: 2px
      line-height: 1.3
      text-align: center
      span 
        display: block
        margin-top: 5px
        font-style: normal
body.category--en, body.category-event-calendar
  .cus-slider
    .title-wrap
      span  
        display: none
@media screen and (max-width: 767px)
  .cus-slider
    .title-wrap
      padding: 25px 40px
      h1.title  
        font-size: 18px
@media screen and (max-width: 576px)
  .cus-slider
    .title-wrap
      padding: 10px 20px
      border: 5px solid rgba(#fff, .6)
      h1.title  
        font-size: 12px
        letter-spacing: 1px
        line-height: 1.5

.about
  margin-top: -75px
  position: relative
  padding: 80px 0
  background-image: url('/wp-content/uploads/index_about_bg.png')
  @extend %bg
  background-position: top center
  
  .item
    display: flex
    align-items: center
    .txt
      box-sizing: border-box
      display: flex
      flex-shrink: 0
      flex-direction: column
      padding: 50px 60px
      background: #fff
      box-shadow: 0 0 5px rgba(0,0,0, .4)
      width: 55%
      margin-right: -5%
      z-index: 10
    .pic
      width: 50%
      flex-shrink: 0  
      z-index: 5
      // img
      //   object-fit: cover
      //   max-height: 360px
      //   object-position: center
    h2 
      color: $colorBlue
      line-height: 1.3
      margin-bottom: 20px
    p 
      font-size: 18px
      color: #919191
      line-height: 1.6
      letter-spacing: 2px
    .btn
      width: 120px
      align-self: flex-end  
      margin-top: 20px
      span.know 
        margin-left: 0
        transition: .3s
      span.plus 
        line-height: 28px
        opacity: 0
        transition: .3s
        padding-left: 3px
      &:hover
        color: #fff
        span.know 
          margin-left: 15px
        span.plus  
          opacity: 1  
@media screen and (max-width: 1180px)
  .about
    .item
      .txt
        max-height: 300px
        padding: 25px 30px
@media screen and (max-width: 992px)
  .about
    .item
      .txt
        max-height: 250px
        padding: 30px 20px  
      p 
        font-size: 16px
        line-height: 1.5  
      .btn 
        font-size: 14px
        width: 100px
        margin-top: 0px
        padding: 3px 15px  
      h2.title 
        font-size: 18px  
@media screen and (max-width: 767px)
  .about 
    padding: 30px 0 80px 0
    .item
      flex-wrap: wrap
      .txt 
        order: 1
        padding: 30px
        max-height: 100%
      .txt, .pic
        width: 100%  
      .btn 
        margin-top: 15px  
@media screen and (max-width: 576px)
  .about 
    padding: 30px 0 30px 0

.products
  padding: 30px 0
  background-image: url('/wp-content/uploads/products_bg.png')
  @extend %bg
  .container
    display: flex
    flex-wrap: wrap
    padding: 0 5px 0 15px
    margin: 0 auto -60px
  h2.title 
    margin: 20px 0 40px 0
    text-align: center
  .item
    box-sizing: border-box
    margin-right: 10px
    width: calc(20% - 10px)
    position: relative
    z-index: 100
    box-shadow: 0 0 6px rgba(#000, .4)
    .txt
      box-sizing: border-box
      display: flex
      justify-content: center
      position: absolute
      width: 100%
      bottom: 0
      left: 0
      height: 32.432432%
      background: #fff
      font-weight: bold
      letter-spacing: 1px
      .title 
        font-style: italic
        font-weight: bold
        font-size: 20px
      .arrow
          position: absolute
          bottom: 10%
          transition: .3s
      &:hover  
        background: rgba($colorBlue, .6)
        .arrow 
          border-top: 6px solid #fff
               
      a.wrap 
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        display: flex
        justify-content: center
        align-items: center
        color: $colorBlue
        transition: all .3s
        &:hover
          color: #fff
@media screen and (max-width: 767px)
  .products
    padding: 30px 15px
     
.news
  padding: 90px 0
  background-image: url('/wp-content/uploads/news_bg.png')
  @extend %bg
  .container
    display: flex
    .title,
    .view
      width: calc(15% - 15px)
    .title 
      margin-right: 15px
    .view 
      margin-bottom: 14px
      .thin-arrow 
        position: absolute
        width: 40px
        left: 60px
        bottom: 10px 
        transition: .3s
      a
        position: relative  
        &:hover 
          .thin-arrow
            left: 90px  
    .news-board
      width: calc(70% - 30px)    
      margin: 45px 15px 0
      li 
        display: flex
        align-items: center
        padding: 20px 0
        & + li 
          border-top: 1px solid #fff
        .newstitle 
          box-sizing: border-box
          padding-left: 25px
          flex: 10
          font-weight: bold  
          color: #15327a
          font-size: 16px
        &:hover
          .newstitle
            opacity: .5 
            font-style: italic
          .enter
            font-style: italic
            a 
              color: #fff
    .date 
      font-style: italic        
    .date,.enter.blue-bg
      box-sizing: border-box
      flex: 2
      border-radius: 0px
      padding: 3px 5px
      line-height: 22px
      font-size: 14px
      letter-spacing: 4px
    
    .view
      display: flex
      justify-content: flex-end
      align-items: flex-end
      text-align: right
      img 
        width: 70%  
        margin-bottom: 10px
@media screen and (max-width: 767px)
  .news 
    padding: 30px 0
    padding-bottom: 60px
    h2.title 
      text-align: center
      margin: 30px 0 20px 0
    .container
      flex-wrap: wrap
      .title, .news-board, .view
        width: 100%  
      .news-board 
        text-align: center
        padding-right: 0
        padding: 0 10px  
        li 
          flex-direction: column  
          align-items: flex-start
      .newstitle
        margin: 15px 0
      .btn 
        align-self: flex-end  
      .view
        justify-content: center
        align-items: center  
        margin-bottom: 30px
        max-width: 200px
        margin: auto
        a 
          display: inline-block
          text-align: center    
@media screen and (max-width: 576px)
  .news 
    h2.title 
      margin: 10px 0 10px 0
    .container
      .news-board 
        margin: 20px 15px 0  
        li 
          .newstitle 
            margin: 20px 0
            padding-left: 0
            text-align: left  
            font-size: 14px
      .date, .enter.blue-bg
        line-height: 16px  
        font-size: 12px

.main-footer
  position: relative
  .go-top 
    position: absolute
    top: -99px
    left: 50%
    transform: translateX(-50%)
    width: 100px
    height: 100px
    overflow: hidden
    box-shadow: 0 16px 10px -17px rgba(#15327a, .9)

    &::after 
      content: ""
      position: absolute
      width: 50px
      height: 50px
      background: #999
      transform: rotate(45deg)
      top: 75px
      left: 25px
      background: #15327a
      box-shadow: -1px -1px 10px -2px rgba(#15327a, .9)

    span 
      position: absolute
      left: 50%
      transform: translateX(-50%)
      top: 78px
      z-index: 100
      font-size: 12px
      font-style: italic
      color: #fff

  header.footer 
    background:#15327a
    height: 50px
    text-align: center
    box-shadow: 0 0 6px rgba(0,0,0,.8)
    li 
      display: inline-block
      font-size: 14px
      line-height: 50px
      padding: 0 15px
      @media screen and (max-width: 568px)
        font-size: 12px
        padding: 0 5px
      &.contact 
        a
          position: relative
          .thin_arrow_white
            position: absolute
            bottom: 5px
            left: 70px
            width: 30%
            opacity: 0
            transition: all .3s
          &:hover 
            .thin_arrow_white
              opacity: 1
              left: 80px

      img 
        width: 100px
    a 
      color: #fff
      text-decoration: none  
  
  .footer-body
    position: relative  
    padding: 50px 0
    background-image: url('/wp-content/uploads/footer_bg.png')
    background-size: cover
    background-repeat: no-repeat
    background-position: center center
    letter-spacing: 2px
    &::before
      content: ''
      position: absolute
      top: 0
      right: 0
      bottom: 0 
      left: 0
      background: rgba(0,0,0,.4)
    .phone 
      font-weight: bold
      font-size: 32px
   
    .container
      position: relative
      text-align: center
      display: flex
      flex-direction: column
      justify-content: space-around
      color: #fff
      z-index: 10
      .logo
        width: 120px
        margin: auto
      > div + div
        margin-top: 30px
        
      .phone, .email
        font-style: italic
      // .address   
  .copyright
    font-size: 12px
    line-height: 2
    text-align: center
    background:#15327a
    color: #fff
      
  
@media screen and (max-width: 812px)
  .container
    max-width: 700px
  ul.main
    > li:focus 
      ul 
        display: block  
  .main-nav 
    ul.main 
      ul
        position: static
        transform: translateX(0)  
        width: 100%
        text-align: left
        &::before
          display: none
  .products 
    .container
      padding-bottom: 90px
    .item
      box-sizing: border-box
      width: calc(33.333333% - 11px)
      margin-bottom: 30px
  
  

  
  
@media screen and (max-width: 567px)
    
  .products 
    .container 
      padding: 0 15px 30px 15px
    .wrap
      flex-wrap: wrap 
    .item
      width: 100%      
      margin-right: 0
      margin-bottom: 20px
      .txt
        height: 25%
        background: rgba($colorBlue, .6)
        .title
          margin-bottom: 15px
          color: #fff
        .arrow
          border-top: 6px solid #fff
          bottom: 21%
      .pic img
        height: 100%
        max-height: 300px
        display: block
        object-fit: cover
        object-position: center
  
        
  
  .main-footer
    .footer-body
      padding: 10px 0
      .phone 
        font-size: 22px
    .copyright 
      padding: 0 15px          






          






