.widget_search 
  margin-bottom: 0
.cus-sidebar 
  input[type=search] 
    padding: 10px
.material-list
  height: 20%
  overflow: hidden
  -webkit-transition: 0.3s
  -o-transition: 0.3s
  transition: 0.3s
  margin: 5px 0

  h2.border-square 
    display: flex
    justify-content: space-between
    align-items: center
  ul 
    margin: 0
    background: #283856
    border-radius: 10px
    color: #fff
    overflow-y: scroll 
    li.title
      font-size: 20px
      border-bottom: 1px solid #fff
      margin: 0 1em
      display: flex
      justify-content: space-between
      align-items: center
      span 
        font-size: 20px
        font-weight: normal
    li
      margin: 0 2em
      span 
        margin-right: 15px
        font-size: 14px
        cursor: pointer
      a
        display: inline-block
    a 
      color: #fff
    &.second 
      overflow: hidden  
  
nav.cus-sidebar
  box-sizing: border-box
  h2
    margin-top: 0
    margin-bottom: 5px
    padding: 10px
    cursor: pointer
    font-size: 16px
    color: #5d6477
    border: 1px solid #5d6477
    border-radius: 5px
  ul
    list-style: none
    padding-left: 0
    &.second 
      li 
        padding: 3px 0
  li
    padding: 7px 0px

    // &:hover
    //   position: relative
    //   background: #518dbe
    //   &.title:hover 
    //     background: transparent

    //   a
    //     color: #fff

    //   &:before
    //     content: ""
    //     position: absolute
    //     left: 0
    //     top: 0
    //     width: 10px
    //     height: 100%
    //     background: #15327a


#sidebar-trigger
  position: absolute
  opacity: 0
  z-index: -1


@media screen and (max-width: 767px)
  #cus-sidebar 
    width: 90%
    max-height: 500px
    position: fixed
    top: 50%
    transform: translateY(-50%)
    left: calc(-90% + 40px)
    padding-right: 40px
    padding-left: 0
    transition: .3s linear
    z-index: 9999
  #menu-wrapper
    box-sizing: border-box
    padding: 30px
    height: 100%
    background: linear-gradient(45deg,rgba(#aaced8, .9), rgba(#e8c5cd, .9))

  #cus-sidebar.open
    left: -15px
    #menu-wrapper
      left: 0

  #cus-sidebar.open
    overflow-y: scroll
    .label 
      left: calc(100% - 40px)
      span 
        transform: rotate(-180deg)
        display: block
        z-index: 999
  .label 
    position: fixed
    top: 50%
    transform: translateY(-50%)
    display: block
    background: #aaced8
    width: 40px
    height: 60px
    line-height: 60px
    z-index: 999
    left: calc(100% - 40px)
    

  #cus-sidebar
    padding-top: 0
    .material-list
      padding: 0px
      margin: 10px 0

  #cus-sidebar h2.border-square
    font-size: 15px

  #cus-sidebar
    li
      padding: 5px 10px
    ul.second 
      li 
        margin: 0 5px

  #cus-sidebar li a
    font-size: 13px