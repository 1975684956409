.swiper-button-next, .swiper-button-prev
  background-image: none 
  background: transparent
  border-radius: 50%
  width: 40px
  height: 40px
  border: 1px solid #0073db
  transition: .3s linear
  &:hover 
    background: #0073db
    
  &:focus 
    outline: none
  &::before 
    content: ''
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    width: 0
    height: 0
    border-style: solid
    z-index: 100
    transition: .3s linear
.swiper-button-next
  margin-right: 20px
  &::before 
    border-width: 8px 0 8px 10px
    border-color: transparent transparent transparent #0073db  
  &:hover 
    &::before
      border-color: transparent transparent transparent #fff
.swiper-button-prev
  margin-left: 20px
  &::before 
    border-width: 8px 10px 8px 0
    border-color: transparent #0073db transparent transparent
  &:hover 
    &::before  
      border-color: transparent #fff transparent transparent

@media screen and (max-width: 767px)
  .swiper-button-next, .swiper-button-prev
    width: 30px
    height: 30px
  
  .swiper-button-next
    &::before 
      border-width: 6px 0 6px 8px
  .swiper-button-prev
    margin-left: 20px
    &::before 
      border-width: 6px 8px 6px 0
@media screen and (max-width: 576px)
  .swiper-button-next, .swiper-button-prev
    width: 20px
    height: 20px
  
  .swiper-button-next
    &::before 
      border-width: 4px 0 4px 6px
  .swiper-button-prev
    margin-left: 20px
    &::before 
      border-width: 4px 6px 4px 0