.products-wrap 
  padding: 90px 0
  
#products_guide
  h2.product-name 
    font-size: 24px
    color: #15327a
    font-weight: bold
    margin-top: 0
  .product-name
    margin-top: 20px
    margin-bottom: 30px
    font-size: 16px
    text-align: center
    color: #7c7f8d


// products info
#products_info
  h2.product-name
    position: relative
    font-weight: bold
    font-size: 24px
    color: #15327a
    letter-spacing: 3px
    padding: 0 0 15px 0
    margin-bottom: 30px
    &::after 
      content: ''
      position: absolute
      width: 60px
      height: 2px
      background: #dbdbdb
      bottom: -5px
      left: 0
  .info-wrap  
    p 
      margin-bottom: 30px
      line-height: 2
      font-size: 16px
      letter-spacing: 1px
      color: #323232
  .cus-tab 
    position: relative
    margin-top: 30px
    h2 
      color: #323232
      font-size: 18px    
      font-weight: bold
      letter-spacing: 1px
      padding: 12px 25px
      width: 130px
      border-top: 1px solid #d1d1d1
      border-left: 1px solid #d1d1d1
      border-right: 1px solid #d1d1d1
    &::after 
      content: ''
      position: absolute
      left: 130px
      bottom: 0
      width: calc(100% - 130px)
      height: 1px
      background: #d1d1d1
  .desc    
    margin-top: 20px
    padding: 0 10px
    .txt 
      line-height: 2
      font-size: 16px
      color: #323232
      margin-bottom: 20px
    .pic 
      width: 50%
@media screen and (max-width: 767px)
  .products-wrap  
    padding: 45px 0 90px 0
  #products_info
    h2.product-name 
      margin: 30px 0
    .cus-tab   
      h2
        padding: 10px
        width: 110px
      &::after  
        left: 110px
    .desc 
      .pic
        width: 100%    
    
